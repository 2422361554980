import React from "react"
import { Nav, Form, Row, Col, Button } from "react-bootstrap"
import { savePref, fetchAPIData } from "../../api/AuthService"
import { SAVE_PREF, FETCH_PREF } from "../../constant/Constants"
import { mapKeys, focusOnSection, checkFilterView } from "./EventHandler"
import WhereUsedModal from "./WhereUsedModal"
class SideNav extends React.Component {
   _isMounted = false
   constructor(props) {
      super(props)
      this.handleClick = this.handleClick.bind(this)
      this.selectedCheckboxes = new Set()
      this.state = {
         toggleSection: "Link",
         disable: true,
         choices: [],
         filters: {},
         loading: false,
         modalShow: false,
      }
      this.Path = window.location.pathname.split("/")[1]
   }

   handleWhereUsed() {
      this.setState({ modalShow: true })
   }
   onCloseModal = () => {
      this.setState({ modalShow: false })
   }

   handleClick(val) {
      window.scrollTo(
         0,
         document.getElementById(val).parentNode.parentNode.offsetTop + 35
      )
      focusOnSection(val)
   }

   handleSave = () => {
      var value = ""
      var completeList = "ALL"

      for (const checkbox of this.selectedCheckboxes) {
         value = value + "|" + checkbox
      }
      var totalItems = document.getElementsByName("UserPrefCheckBox")
      var checkedItems = Array.from(this.selectedCheckboxes)
      if (totalItems.length === checkedItems.length) {
         completeList = "ALL"
      } else {
         completeList = value.substring(1, value.length)
      }
      this.setState({ loading: true })
      savePref(SAVE_PREF, this.Path, completeList)
         .then((res) => {
            var list = this.getKeysObj(Array.from(this.selectedCheckboxes))
            var isFilterView = checkFilterView()
            this.props.handler(list, isFilterView)
            this.setState({ toggleSection: "Link" })
            this.setState({ loading: false })
         })
         .catch((error) => {
            console.log(" error", error)
            this.setState({ loading: false })
         })
   }

   toggleCheckbox = (e) => {
      this.selectedCheckboxes.add("whereUsed")
      
      if (this.selectedCheckboxes.has(e.target.value)) {
         this.selectedCheckboxes.delete(e.target.value)
      } else {
         this.selectedCheckboxes.add(e.target.value)
      }
      this.setState({
         disable: this.selectedCheckboxes.size === 1 ? true : false,
         choices: Array.from(this.selectedCheckboxes),
      })
      if (this.selectedCheckboxes.size > 0) {
         var list = this.getKeysObj(Array.from(this.selectedCheckboxes))

         var isFilterView = checkFilterView()
         this.props.handler(list, isFilterView)
      }
   }

   getNavLinks() {
      if (this.props.data) {
         var keys = []
         for (var k = 0; k < this.props.data.length; k++) {
            if (this.props.data[k].childNodes[1].nextSibling != null) {
               keys[k] =
                  this.props.data[k].childNodes[1].nextSibling.id +
                  "|" +
                  this.props.data[k].innerText
            } else {
               keys[k] =
                  this.props.data[k].childNodes[1].id + "|" + this.props.data[k].innerText
            }
         }
         var elements = keys.map((value, key) => {
            return (
               <button
                  key={key}
                  className="sideNavButton"
                  onClick={() => {value.split("|")[1] == "Where Used " ? this.handleWhereUsed() : this.handleClick(value.split("|")[0])}}
                  title={value.split("|")[1]}
               >
                  <i className="fa fa-angle-right"></i> &nbsp;
                  {value.split("|")[1].length > 25
                     ? value.split("|")[1].substring(0, 23) + "..."
                     : value.split("|")[1]}
               </button>
            )
         })
         return elements
      }
   }

   handleLinkClick = (e) => {
      this.setState({ toggleSection: "Link" })
   }
   handleFileters = (e) => {
      this.setState({ toggleSection: "Filters" })
      for (var i = 0; i < this.state.choices.length; i++) {
         this.selectedCheckboxes.add(this.state.choices[i])
      }
      this.setState({
         disable: (this.selectedCheckboxes.size === 0 || this.selectedCheckboxes.size === 1) ? true : false,
      })
   }

   getButtons() {
      if (this.props.data) {
         return (
            <Row style={{ margin: "3px" }}>
               <Col className="leftSection shadow">
                  <button
                     className={
                        this.state.toggleSection === "Link"
                           ? "activeButton"
                           : "inactiveButton"
                     }
                     onClick={this.handleLinkClick}
                  >
                     Links
                  </button>
               </Col>
               <Col className="leftSection shadow">
                  <button
                     className={
                        this.state.toggleSection === "Filters"
                           ? "activeButton"
                           : "inactiveButton"
                     }
                     onClick={this.handleFileters}
                  >
                     Filters
                  </button>
               </Col>
            </Row>
         )
      }
   }

   getCheckBoxes() {
      if (this.state.filters) {
         var items = this.state.filters
         var elements = Object.keys(items).map((value, key) => {
            return (
               <Form.Group controlId={100 + key} key={100 + key} className="formGroup">
                  <Form.Check
                     type="checkbox"
                     label={
                        items[value].length > 25
                           ? items[value].substring(0, 25) + "..."
                           : items[value]
                     }
                     value={value}
                     name="UserPrefCheckBox"
                     className="checkboxStyle"
                     onChange={this.toggleCheckbox}
                     key={100 + key}
                     checked={items[value] == "Where Used" ? true : (this.state.choices.includes(value) ? true : false)}
                     disabled={items[value] == "Where Used" ? true : false}
                     title={items[value]}
                  />
               </Form.Group>
            )
         })
         return elements
      }
   }

   selectAll = () => {
      this.selectedCheckboxes.clear()
      var items = document.getElementsByName("UserPrefCheckBox")
      for (var i = 0; i < items.length; i++) {
         if (items[i].type === "checkbox") {
            items[i].checked = true
            this.selectedCheckboxes.add(items[i].value)
         }
      }
      var list = this.getKeysObj(Array.from(this.selectedCheckboxes))
      var isFilterView = checkFilterView()
      this.props.handler(list, isFilterView)
      this.setState({
         disable: false,
         choices: Array.from(this.selectedCheckboxes),
      })
   }
   UnSelectAll = () => {
      var items = document.getElementsByName("UserPrefCheckBox")
      for (var i = 0; i < items.length; i++) {
         if (items[i].type === "checkbox") items[i].checked = false
      }
      this.selectedCheckboxes.clear()
      this.selectedCheckboxes.add("whereUsed")
      this.setState({
         disable: true,
         choices: Array.from(this.selectedCheckboxes),
      })
   }

   getKeysObj = (obj) => {
      var list = {}
      for (var i = 0; i < obj.length; i++) {
         list[obj[i]] = this.state.filters[obj[i]]
      }
      return list
   }

   getChoicesData = async () => {
      var handleToUpdate = this.props.handler
      var Link = localStorage.getItem("UserId") + "/objectName/" + this.Path
      var items = mapKeys(this.props.resData, this.props.Keys)
      var isFilterView = false

      this.setState({ filters: items })

      try {
         const userRes = await fetchAPIData(`${FETCH_PREF}/${Link}`)
         if (userRes && !userRes.data.includes("Not Found")) {
            if (userRes.data === "ALL") {
               if (this._isMounted) {
                  handleToUpdate(items, isFilterView)
                  this.setState({
                     toggleSection: "Link",
                     choices: Object.keys(items),
                  })
               }
            } else {
               var selectedItem = userRes.data.split("|")
               var itemKeys = Object.keys(items)
               var common = selectedItem.filter((x) => itemKeys.indexOf(x) !== -1)

               if (common.length > 0) {
                  var list = this.getKeysObj(common)
                  if (items && selectedItem) {
                     if (itemKeys.length !== selectedItem.length) {
                        isFilterView = true
                     }
                  }
                  handleToUpdate(list, isFilterView)
               } else {
                  handleToUpdate(items, isFilterView)
               }
               if (this._isMounted) {
                  this.setState({
                     toggleSection: "Link",
                     choices: common,
                  })
               }
            }
         } else {
            if (this._isMounted) {
               handleToUpdate(items, isFilterView)
               this.setState({
                  toggleSection: "Link",
               })
            }
         }
      } catch (error) {
         handleToUpdate(this.props.Keys, isFilterView)
         this.setState({
            toggleSection: "Link",
         })
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (this.props.resData !== prevProps.resData) {
         this._isMounted = true
         this.getChoicesData()
      }
     }

   componentDidMount() {
      if (this.props.handler) {
         this._isMounted = true
         this.getChoicesData()
      }
   }
   componentWillUnmount() {
      this._isMounted = false
   }

   render() {
      return (
         <React.Fragment>
            <div style={{ margin: "2px" }}>
               {this.getButtons()}
               {this.state.toggleSection === "Link" ? (
                  <>
                  <Nav
                     className="flex-column"
                     style={{ marginTop: "10px" }}
                  >
                     {this.getNavLinks()}
                  </Nav>
               </>
               ) : (
                  <Nav
                     className="flex-column"
                     style={{ marginTop: "10px", marginBottom: "20px" }}
                  >
                     {this.getCheckBoxes()}
                     <Row style={{ margin: "3px", marginTop: "5px" }}>
                        <Col className="leftSection" align="center">
                           <Button className="activeButton" onClick={this.selectAll}>
                              Select&nbsp;All
                           </Button>
                        </Col>

                        <Col
                           style={{ marginLeft: "3px" }}
                           className="leftSection"
                           align="center"
                        >
                           <Button className="activeButton" onClick={this.UnSelectAll}>
                              Unselect&nbsp;All
                           </Button>
                        </Col>
                     </Row>
                     <Row style={{ marginTop: "5px" }}>
                        <Col className="btnContainer" align="center">
                           <Button
                              style={{ width: "90px", textAlign: "center" }}
                              className="expandCollapseButton"
                              onClick={this.handleSave}
                              disabled={this.state.disable}
                           >
                              {this.state.loading ? (
                                 <i
                                    className="fa fa-spinner fa-spin"
                                    style={{ marginRight: "5px" }}
                                 />
                              ) : (
                                 "Save"
                              )}
                           </Button>
                        </Col>
                     </Row>
                  </Nav>
               )}
            </div>
            <WhereUsedModal
               show={this.state.modalShow}
               onHide={this.onCloseModal}
               name={this.props.Name}
               objectid={this.props.searchData}
               Rev={this.props.Revision}
            />
         </React.Fragment>
      )
   }
}
export default SideNav
