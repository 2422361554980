import React, { useEffect, useState } from "react"
import {
   Alert,
   Button,
   Col,
   Container,
   Form,
   FormControl,
   InputGroup,
   Row
} from "react-bootstrap"
import { withRouter } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { isLoggedIn } from "../../api/AuthService"
import Logo from "../../resource/img/logo.png"
import SearchOptions from "../commonComponents/SearchOptions"
import Logout from "../login/Logout"
import BreadCrumb from "./BreadCrumb"
import VendorPreference from "../search/VendorPreference"

function HeaderComponent(props) {
   var searchType = "gcas"

   const view =
      props.pdfDataSet && props.pdfDataSet["isFilterView"] === true
         ? props.pdfDataSet["isFilterView"]
         : false

   searchType =
      props.match.params && props.match.params.searchType
         ? props.match.params.searchType
         : searchType

   const [searchData, setSearchData] = useState(props.value ? props.value : "")
   const [message, setMessage] = useState("")
   const [blinkSeacrh, setblinkSeacrh] = useState("")
   const [inProp, setInProp] = useState(false)
   const [optionValue, setOptionValue] = useState(props.type ? props.type : searchType)
   const[vendorModal,setVendorModal]=useState(false)
   const [initalOptionValue, setInitalOptionValue] = useState(
      props.type ? props.type : searchType
   )

   var searchText = React.createRef()

   function handleSubmit(e) {
      sessionStorage.removeItem("breadCrumb")
      e.preventDefault()
      if (validateForm()) {
         props.history.push(`/search/${btoa(encodeURIComponent(searchData))}/${optionValue}`)
      }
   }
   const handleVendorModal = () => {
      
         setVendorModal(!vendorModal)
   
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      }
      setInitalOptionValue(searchType)
      setOptionValue(searchType)
      setSearchData(props.value ? decodeURIComponent(atob(props.value)) : "")
      setInProp(true)

      return () => {
         return undefined
      }
   }, [props, searchType])

   function validateForm() {
      let formIsValid = true
      if (searchData === "") {
         formIsValid = false
         setMessage("* Please enter some value above.")
         setblinkSeacrh("highlightFields")
      }
      return formIsValid
   }

   function handleChange(event) {
      setMessage("")
      setblinkSeacrh("")
      setSearchData(event.target.value)
   }

   const handleChooseOption = (option) => {
      setOptionValue(option)
      searchText.current.focus()
   }

   return (
      <Container className="contentBackground sticky-top shadow banner" id="headcontainer" fluid>
         <Row style={{ height: "30px" }}>
            <Col sm="1" md="3" style={{ paddingLeft: "3px" }}>
               <img
                  style={{ marginTop: ".8rem", cursor: "pointer", paddingLeft: "3px" }}
                  src={Logo}
                  alt="logo"
                  onClick={() => props.history.push("/search")}
               />
            </Col>
            <Col sm="1" md="1"></Col>
            <Col sm="5" md="4">
               <br />
               <Form onSubmit={handleSubmit}>
                  <InputGroup>
                     <SearchOptions
                        value={initalOptionValue}
                        handleChooseOption={handleChooseOption}
                     />
                     <FormControl
                        style={{ height: "35px" }}
                        ref={searchText}
                        className={`placeHolderText searchBox shadow ${blinkSeacrh}`}
                        type="text"
                        value={searchData}
                        placeholder="&#61442; What are you Looking For?"
                        onChange={handleChange}
                     />
                     <InputGroup.Append style={{ height: "35px" }}>
                        <Button bsPrefix="searchBtnText_copy" type="submit">
                           <i className="fa fa-search"></i>
                        </Button>
                     </InputGroup.Append>
                  </InputGroup>
                  <Form.Row>
                     <Col>
                        <p style={{ marginTop: "4px" }} className="alertMsg">
                           {message}
                        </p>
                     </Col>
                  </Form.Row>
               </Form>
            </Col>
            <Col sm="5" md="4" className="mt-3">
               <Logout handleVendorModal={handleVendorModal} />
            </Col>
         </Row>
         <Row style={{ height: "59px" }}>
            <Col style={{ marginTop: "15px" }} className="accordIconStyle">
               <CSSTransition in={inProp} timeout={300} classNames="my-node">
                  <BreadCrumb props={props} />
               </CSSTransition>
            </Col>
         </Row>
         {(view || props.css) && (
            <Row>
               <Col style={{ marginTop: "5px", textAlign: "center" }}>
                  <Alert
                     style={{
                        padding: "0px",
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "red",
                     }}
                     variant="primary"
                  >
                     {view && 
                        <>
                        Filtered View
                        <br/>
                        </>
                     }
                     {props.css &&
                        <>
                        CSS Objects shall display limited categories. Conversions will no longer be supported and new DSM Data Model objects will need to be created in Enovia 3DX.
                        </>
                     }
                  </Alert>
               </Col>
            </Row>
         )}
          <VendorPreference show={vendorModal} handleVendorModal={handleVendorModal}/>
      </Container>
   )
}
export default withRouter(HeaderComponent)
