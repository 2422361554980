import React, { useState, useEffect } from "react"
import { Button, Form, Modal, Col } from "react-bootstrap"
import { isLoggedIn, EBPIDsForEmail } from "../../api/AuthService"
import { EMAIL_DATA } from "../../constant/Constants"

function VendorMailModal(props) {
   const [mailBody, setMailBody] = useState("")
   const [toField, setToField] = useState([])
   const [ccField, setCCField] = useState('')
   const [isValid, setIsValid] = useState(false)
   const [isSubmitClicked, setIsSubmitClicked] = useState(false)
   const [error, setError]=useState('')
   const [change, setChange] = useState(false)
   const [blinkSearch, setBlinkSearch] = useState("")
   const [blinkSearch1, setBlinkSearch1] = useState("")
   const [message, setMessage] = useState("")
   const [success, setSuccess] = useState(true)
   const [subject, setSubject] = useState("")
   const [subjectChange, setSubjectChange ] = useState(false)
   const [mailError, setMailError] = useState('')
   const [mailSuccess, setMailSuccess] = useState(false)
   const [subSuccess, setSubSuccess] = useState(false)
   const [failedMessage, setFailedMessage] = useState("")

   const handleInputChange = (e) => {
      setChange(true)
      setToField(e.target.value)
      emailValidation(e.target.value)
   }

   const handleSubjectChange = (e) => {
      setSubSuccess(true)
      setSubjectChange(true)
      if(textValidation(e.target.value)){
         setSubject(e.target.value)
      } else {
         setSubject(e.target.value.substring(0, e.target.value.length - 1))
      }
   }

   const fetchTOandCC = async (ids) => {
      const data = {
         objectId: ids
      }
      EBPIDsForEmail(`${EMAIL_DATA}`, data)
         .then((response) => {
            if (response.data) {
               setToField(response.data.To)
               emailValidation(response.data.To.join(','))
               setCCField(response.data.CC)
            }
         })
         .catch((error) => {
            console.error("API errr", error)
            setSuccess(false)
            setFailedMessage("There is an issue with integration services. Please contact support!")
         })
   }

   function emailValidation(value) {
      const emails = value.split(/[,]+/)
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsSubmitClicked(false)
      const haspgid = emails.some(email => email.includes('pg.com') && emailRegex.test(email))

      if(emails.every(email => emailRegex.test(email)) && haspgid) {
         setIsValid(true)
      } else if(!emails.every(email => emailRegex.test(email))) {
         setIsValid(false)
         setError("Please enter valid email ids separated by comma")
      } else if(!haspgid) {
         setIsValid(false)
         setError("Enter valid email ids containing atleast one pg id")
      } else {
         setIsValid(false)
         setError("Please enter valid email ids separated by comma")
      }
   }

   function textValidation(value) {
      let exp = "^[A-Za-z0-9 \\n\\t,()&.-]+$"
      if (value.match(exp)) {
         return true
      } else {
         return false
      }
   }

   function handleMailBodyChange(e) {
      if (e.target.value === "") {
         setMailBody("")
         setMailSuccess(false)
      } else {
         setMailSuccess(true)
         let checkedText = textValidation(e.target.value)
         if (checkedText) {
            setMailBody(e.target.value)
         }
      }
   }

   function handleSubmit(e) {
      setIsSubmitClicked(true)
      let data = props.moveData
      data = { ...data, 
         'inWorkMessage': mailBody,
         'emailsubject':subjectChange ? subject.toString() : props.selectedNames.toString(), 
         'to': change ? Array.from(toField.split(",")) : toField, 
         'cc': ccField 
      }
      if(isValid && mailBody.length > 0 && subject.length > 0) {
         props.setShow(false)
         props.moveFromView(data)
         setMailBody("") 
      } else {
         if(mailBody.length === 0) {
            setBlinkSearch("highlightFields")
            setMailSuccess(false)
            setMailError("Please enter the reason for rejection.")
            setBlinkSearch1("")
         }
         if(subject.length === 0) {
            setBlinkSearch1("highlightFields")
            setSubSuccess(false)
            setMessage("Please enter the subject for email.")
            setBlinkSearch("")
         }
         if(toField.length === 0) {
            setSuccess(false)
            setError("Please enter email ids containing atleast one pg id")
         }
      }
   }

   function handleSubmitClose(e) {
      props.setShow(false)
      setSuccess(true)
      setMailBody("")
      setMailSuccess(true)
      setSubSuccess(true)
      setBlinkSearch("")
      setBlinkSearch1("")
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         if(props.show){
            fetchTOandCC(props.sendSelectedIds)
            setSubject(props.selectedNames.toString())
         }
      }
   }, [props.show, props.sendSelectedIds,props.selectedNames])

   return (
      <Modal
         aria-labelledby="contained-modal-title-vcenter"
         centered
         dialogClassName="my-modal"
         style={{ overflowY: "hidden" }}
         backdrop="static"
         keyboard={false}
         show={props.show}
      >
         <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Email details</Modal.Title>
            <button type="button" className="close" onClick={handleSubmitClose}>&times;</button>
         </Modal.Header>
         <Modal.Body>
            <Form>
               {
                  !success &&
                  <Form.Row>
                        <Col md="11" sm="11" align="center">
                           <p style={{ marginTop: "4px", fontWeight: "bold" }} className='alertMsg'>
                              {failedMessage}
                           </p>
                        </Col>
                  </Form.Row>
               }
               <Form.Group className="mb-3">
                  <Form.Label>To</Form.Label>
                  <Form.Control 
                     type="text" 
                     value={toField}
                     onChange={handleInputChange}
                     placeholder="Enter email address separated by comma"
                     className={!isValid && isSubmitClicked ? 'is-invalid' : ''}
                     />
                  <Form.Text className='text-danger'>
                     {!isValid && isSubmitClicked ? error : ''}
                  </Form.Text>
                  <Form.Label>CC</Form.Label>
                  <Form.Control type="text" value={ccField} readOnly />
                  <Form.Group className="mb-3">
                     <Form.Label>Subject</Form.Label>
                     <Form.Control  
                        className={subSuccess ? '' : `${blinkSearch1}`} 
                        type="text" 
                        value={subjectChange ? subject : props.selectedNames} 
                        onChange={handleSubjectChange} 
                     />
                     {
                        !subSuccess && subject.length === 0 &&
                        <Form.Text className='text-danger'>
                           {message}
                        </Form.Text>
                     }
                  </Form.Group> 
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Email Body</Form.Label>
                  <Form.Control
                     as="textarea"
                     className={mailSuccess ? '':`${blinkSearch}`}
                     placeholder="Type message"
                     rows={3}
                     onChange={handleMailBodyChange}
                     value={mailBody}
                     autoFocus
                  />
                  {
                     !mailSuccess && mailBody.length === 0 &&
                     <Form.Text className='text-danger'>
                        {mailError}
                     </Form.Text>
                  }
               </Form.Group>
            </Form>
            <p>
               Note: Allowed special characters for Subject and Email Body are parentheses(()), ampersand(&), period(.), comma(,), hyphen(-) only.
            </p>
         </Modal.Body>
         <Modal.Footer>
            <Button value="Close" onClick={handleSubmitClose}>Close</Button>
            <Button value="Submit" onClick={handleSubmit}>Send Email</Button>
         </Modal.Footer>
      </Modal>
   )
}
export default VendorMailModal
