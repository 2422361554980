import React, { useEffect } from "react"
import { Button, Form, Table, Tooltip, OverlayTrigger } from "react-bootstrap"
import { Link } from "react-router-dom"
import { addBreadCrumbLinks } from "../commonComponents/EventHandler"
import { isLoggedIn } from "../../api/AuthService"

const CommonView = (props) => {
   const childDataLink = (child) => {
      return child.link != null && child.Id != null ? (
         <Link
            target="_blank"
            rel="noreferrer noopener"
            onClick={() =>
               addBreadCrumbLinks(
                  child.Name,
                  `/${child.link.toUpperCase().trim()}/${child.Id.trim()}`
               )
            }
            to={(location) => ({
               ...location,
               pathname: `/${child.link.toUpperCase()}/${child.Id}`,
            })}
         >
            <span textalign="left">{child.Name}</span>
         </Link>
      ) : (
         <span>{child.Name}</span>
      )
   }

   const tooltip = (
      <Tooltip id="tooltip">
         All Impacted Parts might not be listed in this View. Please click on the
         hyperlink to view if there are more Impacted Parts.
      </Tooltip>
   )

   function checkViewMoreParts(key) {
      if (key.viewMoreParts) {
         return (
            <td align="center" colSpan={5}>
               <OverlayTrigger placement="top" overlay={tooltip}>
                  <Button
                     style={{
                        color: "#007bff",
                        backgroundColor: "white",
                        border: "none",
                     }}
                     onClick={() => props.handleViewMorePart(key.Name)}
                  >
                     View more Impacted Parts
                  </Button>
               </OverlayTrigger>
            </td>
         )
      } else {
         return (
            <>
               <td align="left" width="10%">
                  {}
               </td>
               <td align="left" width="10%">
                  {}
               </td>
               <td align="left" width="10%">
                  {}
               </td>
               <td align="left" width="10%">
                  {}
               </td>
               {props.view == "Pending" && (
                  <td align="left" width="5%">
                     {}
                  </td>
               )}
            </>
         )
      }
   }

   const childData = (childCount, key, index) => {
      let rowSpan = childCount
      if (childCount > 5) {
         rowSpan = 6
      } else if (key.viewMoreParts) {
         rowSpan += 1
      }
      return key["ImpactedParts"].map((childRow, childIndex) => {
         if (childIndex == 0) {
            return (
               <>
                  <tr key={childIndex}>
                     <td align="center" width="5%" rowSpan={rowSpan}>
                        {" "}
                        <Form.Check
                           type="checkbox"
                           name="tableCheckbox"
                           checked={props.isChecked(key.ebpId)}
                           onChange={() =>
                              props.handleRowCheckBoxChange(key.ebpId, key.Name, key.Id)
                           }
                        />
                     </td>
                     <td width="10%" rowSpan={rowSpan}>
                        {key.link != null && key.Id != null ? (
                           <Link
                              target="_blank"
                              rel="noreferrer noopener"
                              onClick={() =>
                                 addBreadCrumbLinks(
                                    key.Name,
                                    `/${key.link.toUpperCase().trim()}/${key.Id.trim()}`
                                 )
                              }
                              to={(location) => ({
                                 ...location,
                                 pathname: `/${key.link.toUpperCase()}/${key.Id}`,
                              })}
                           >
                              <span textalign="left">{key.Name}</span>
                           </Link>
                        ) : (
                           <span>{key.Name}</span>
                        )}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.Type}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.SpecSubType}
                     </td>
                     <td align="left" width="5%" rowSpan={rowSpan}>
                        {key.Rev}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.Title}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.ReasonForChange}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.ReleaseDate}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.EBPModified}
                     </td>
                     {props.view == "Pending" && (
                        <td align="left" width="5%" rowSpan={rowSpan}>
                           {
                              <Button
                                 className="fa fa-download"
                                 aria-hidden="true"
                                 variant="link"
                                 disabled={props.pdfDisable}
                                 onClick={() =>
                                    props.handlePDFDownload(
                                       key.Name,
                                       key.link,
                                       key.Id,
                                       key.Rev
                                    )
                                 }
                              ></Button>
                           }
                        </td>
                     )}
                     <td align="left" width="10%">
                        {childDataLink(childRow)}
                     </td>
                     <td align="left" width="10%">
                        {childRow["DisplayType"]}
                     </td>
                     <td align="left" width="5%">
                        {childRow["Revision"]}
                     </td>
                     <td align="left" width="10%">
                        {childRow["Title"]}
                     </td>
                     {props.view == "Pending" && (
                        <td align="left" width="5%">
                           {
                              <Button
                                 className="fa fa-download"
                                 aria-hidden="true"
                                 variant="link"
                                 disabled={props.pdfDisable}
                                 onClick={() =>
                                    props.handlePDFDownload(
                                       childRow.Name,
                                       childRow.link,
                                       childRow.Id,
                                       childRow.Revision
                                    )
                                 }
                              ></Button>
                           }
                        </td>
                     )}
                  </tr>
                  {childIndex == childCount - 1 && key.viewMoreParts && (
                     <tr key={childCount}>
                        <td align="center" colSpan={5}>
                           <OverlayTrigger placement="top" overlay={tooltip}>
                              <Button
                                 style={{
                                    color: "#007bff",
                                    backgroundColor: "white",
                                    border: "none",
                                 }}
                                 onClick={() => props.handleViewMorePart(key.Name)}
                              >
                                 View more Impacted Parts
                              </Button>
                           </OverlayTrigger>
                        </td>
                     </tr>
                  )}
               </>
            )
         } else if (childIndex > 0 && childIndex < 5) {
            return (
               <>
                  <tr key={childIndex}>
                     <td align="left" width="10%">
                        {childDataLink(childRow)}
                     </td>
                     <td align="left" width="10%">
                        {childRow["DisplayType"]}
                     </td>
                     <td align="left" width="5%">
                        {childRow["Revision"]}
                     </td>
                     <td align="left" width="10%">
                        {childRow["Title"]}
                     </td>
                     {props.view == "Pending" && (
                        <td align="left" width="5%">
                           {
                              <Button
                                 className="fa fa-download"
                                 aria-hidden="true"
                                 variant="link"
                                 disabled={props.pdfDisable}
                                 onClick={() =>
                                    props.handlePDFDownload(
                                       childRow.Name,
                                       childRow.link,
                                       childRow.Id,
                                       childRow.Revision
                                    )
                                 }
                              ></Button>
                           }
                        </td>
                     )}
                  </tr>
                  {childIndex == childCount - 1 && key.viewMoreParts && (
                     <tr key={childCount}>
                        <td align="center" colSpan={5}>
                           <OverlayTrigger placement="top" overlay={tooltip}>
                              <Button
                                 style={{
                                    color: "#007bff",
                                    backgroundColor: "white",
                                    border: "none",
                                 }}
                                 onClick={() => props.handleViewMorePart(key.Name)}
                              >
                                 View more Impacted Parts
                              </Button>
                           </OverlayTrigger>
                        </td>
                     </tr>
                  )}
               </>
            )
         } else if (childIndex == 5) {
            return (
               <tr key={childIndex}>
                  <td align="center" colSpan={5}>
                     <OverlayTrigger placement="top" overlay={tooltip}>
                        <Button
                           style={{
                              color: "#007bff",
                              backgroundColor: "white",
                              border: "none",
                           }}
                           onClick={() => props.handleViewMorePart(key.Name)}
                        >
                           View more Impacted Parts
                        </Button>
                     </OverlayTrigger>
                  </td>
               </tr>
            )
         }
      })
   }

   const info_content = props.currentResult.map((key, index) => {
      let childCount = key["ImpactedParts"].length
      if (childCount == 0) {
         return (
            <tr key={index}>
               <td align="center" width="5%">
                  {" "}
                  <Form.Check
                     type="checkbox"
                     name="tableCheckbox"
                     checked={props.isChecked(key.ebpId)}
                     onChange={() => props.handleRowCheckBoxChange(key.ebpId, key.Name, key.Id)}
                  />
               </td>
               <td width="10%">
                  {key.link != null ? (
                     <Link
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={() =>
                           addBreadCrumbLinks(
                              key.Name,
                              `/${key.link.toUpperCase().trim()}/${key.Id.trim()}`
                           )
                        }
                        to={(location) => ({
                           ...location,
                           pathname: `/${key.link.toUpperCase()}/${key.Id}`,
                        })}
                     >
                        <span textalign="left">{key.Name}</span>
                     </Link>
                  ) : (
                     <span>{key.Name}</span>
                  )}
               </td>
               <td align="left" width="10%">
                  {key.Type}
               </td>
               <td align="left" width="10%">
                  {key.SpecSubType}
               </td>
               <td align="left" width="5%">
                  {key.Rev}
               </td>
               <td align="left" width="10%">
                  {key.Title}
               </td>
               <td align="left" width="10%">
                  {key.ReasonForChange}
               </td>
               <td align="left" width="10%">
                  {key.ReleaseDate}
               </td>
               <td align="left" width="10%">
                  {key.EBPModified}
               </td>
               {props.view == "Pending" && (
                  <td align="left" width="5%">
                     {
                        <Button
                           className="fa fa-download"
                           aria-hidden="true"
                           variant="link"
                           disabled={props.pdfDisable}
                           onClick={() =>
                              props.handlePDFDownload(key.Name, key.link, key.Id, key.Rev)
                           }
                        ></Button>
                     }
                  </td>
               )}
               {checkViewMoreParts(key)}
            </tr>
         )
      } else return childData(childCount, key, index)
   })

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         props.requestSort("EBPModified")
      }
   }, [])

   return (
      !props.fromViewMore && (
         <div className="vendorTable">
            <Table bordered id="vendorInfoTable">
               <thead id="infoTab">
                  <tr>
                     <th width="5%">
                        <Form.Check
                           type="checkbox"
                           style={{ textAlign: "center" }}
                           id="gridViewCheckbox"
                           onChange={props.handleHeaderCheckBoxChange}
                           checked={props.isChecked("All")}
                        />
                     </th>
                     <th width="10%">
                        Name&nbsp;
                        <i
                           onClick={() => props.requestSort("Name")}
                           className={props.getClassNamesFor("Name")}
                        ></i>
                     </th>
                     <th width="10%">
                        Type &nbsp;
                        <i
                           onClick={() => props.requestSort("Type")}
                           className={props.getClassNamesFor("Type")}
                        ></i>
                     </th>
                     <th width="10%">Spec Sub Type &nbsp;</th>
                     <th width="5%">Rev &nbsp;</th>
                     <th width="10%">Title &nbsp;</th>
                     <th width="10%">Reason For Change &nbsp;</th>
                     <th width="10%">
                        Release Date &nbsp;
                        <i
                           onClick={() => props.requestSort("ReleaseDate")}
                           className={props.getClassNamesFor("ReleaseDate")}
                        ></i>
                     </th>
                     <th width="10%">
                        Modified Date &nbsp;
                        <i
                           onClick={() => props.requestSort("EBPModified")}
                           className={props.getClassNamesFor("EBPModified")}
                        ></i>
                     </th>
                     {props.view == "Pending" && <th width="5%"></th>}
                     <th width="10%">Impacted Part(s) &nbsp;</th>
                     <th width="10%">Type &nbsp;</th>
                     <th width="5%">Rev &nbsp;</th>
                     <th width="10%">Title &nbsp;</th>
                     {props.view == "Pending" && <th width="5%"></th>}
                  </tr>
               </thead>
               <tbody id="infoTab">{info_content}</tbody>
            </Table>
            {props.currentResult.length === 0 && props.searchInput === undefined && (
               <p style={{ marginTop: "4px", fontWeight: "bold" }} className={"alertMsg"}>
                  No Records to show
               </p>
            )}
            {props.searchInput !== "" &&
               props.searching &&
               props.dataSearched.length === 0 && (
                  <p
                     style={{ marginTop: "4px", fontWeight: "bold" }}
                     className={"alertMsg"}
                  >
                     No results found matching the text
                  </p>
               )}
         </div>
      )
   )
}

export default CommonView
